<template>
    <div :id="id" ref="qrCodeDiv"></div>
</template>
<script>
  import './index.less'
  import QRCode from 'qrcodejs2';
    export default{
      props: {
          value: {
            type: String,
            default: '.'
          },
          id: {
            type: Number,
            default: 0
          },
          height: {
            type: Number,
            default: 200
          },
          width: {
            type: Number,
            default: 200
          },
        },
        data(){
            return {
              msg: 'hello vue',
              codes:''

            }
        },
      // watch: {
      //   value: function(url){
      //     console.log(url)
      //   }
      // },
      methods: {
        useqrcode(){
          let that = this;
          new QRCode(this.$refs.qrCodeDiv, {
            text: that.value,
            width: that.width,
            height: that.height,
            colorDark: "#333333", //二维码颜色
            colorLight: "#ffffff", //二维码背景色
            correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
          })
        }
      },

      mounted(){
        // console.log(this.value)
        this.useqrcode();
      }
    }
</script>
